import React, { useState, useRef } from "react";
var Hashes = require('jshashes')

function CreateNewUser() {

    const  [userId, setUserId] =  useState('');
    const  [orgId, setOrgId] =  useState('');
    const  [password, setPassword] =  useState('');
    const  [msg, setMsg] =  useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        if(userId === '' || orgId === '' || password === ''){
            return;
        }

        const hashedPassword = new Hashes.SHA1().b64(password)

        const data = {
            userId: userId,
            orgId: orgId,
            password: hashedPassword
        }

        fetch('/api/createUser', {
            credentials: 'include',
            withCredentials: true,
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'withCredentials': true
            },
        })
        .then(response => {
            console.log(response.status)
            if(response.status !== 200){
               setMsg("some thing went wrong...")
            }
            return response.json();
        })
        .then(data =>{
            if(data !== null){
                console.log(data)
                setUserId('')
                setOrgId('')
                setPassword('')
                setMsg(JSON.stringify(data))
            }
        })
    }

    const handleUserIdChange = (event) => {
        event.preventDefault();
        setUserId(event.target.value);
    }

    const handlePasswordChange = (event) => {
        event.preventDefault();
        setPassword(event.target.value);
    }

    const handleOrgIdChange = (event) => {
        event.preventDefault();
        setOrgId(event.target.value);
    }


    return (
        <div className='content-centered pad-top'>
            <div className="card">
                <br />
                <h1><i className={"fa fa-user-plus"} /></h1>
                <h4>Create new User</h4>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <input type="text" className='input-field' placeholder="userId" value={userId} onChange={handleUserIdChange}/>
                    <input type="text" className='input-field' placeholder="orgId" value={orgId} onChange={handleOrgIdChange}/>
                    <input type="text" className='input-field' placeholder="password" value={password} onChange={handlePasswordChange}/>
                    <input type="submit" value="Create New Org" />
                    {msg!=='' ? <div>{msg}</div> : null}
                </form>
            </div>
        </div>
    );
}

export default CreateNewUser;