import React, { useEffect, useContext, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { SocketContext } from '../socket'
import { ToastProvider, useToasts, } from 'react-toast-notifications';
import Constant from '../Config';
import Toggle from './Toggle';
import QueueReset from './QueueReset';
import Footer from './Footer';

function Updater() {
    const navigate = useNavigate();
    const socket = useContext(SocketContext);
    const [currentNumber, setCurrentValue] = useState('-')
    const [calledNumber, setCalledNumber] = useState([12, 15]);
    const [orgName, setOrgName] = useState("");
    const [dateTime, setDateTime] = useState('test...');
    const refInput = useRef(null);
    const { addToast } = useToasts();
    const [orgModel, setOrgModel] = useState(undefined);



    const handleSubmit = (event) => {
        event.preventDefault();
        let updateValue = refInput.current.value;
        if (refInput.current.value === '') {
            addToast('Enter a number!!', { appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000 });
            refInput.current.focus();
            return;
        }

        fetch("/api/incr",
            {
                credentials: 'include',
                withCredentials: true,
                method: 'POST',
                body: JSON.stringify({
                    "newNumber": updateValue
                }),
                headers: {'Content-type': 'application/json; charset=UTF-8'},
            })
            .then(response => {
                if (response.status !== 200) {
                    navigate('/org/login')
                }
                else {
                    return response.json()
                }
            })
            .then(data => {
                if (data !== undefined) {
                    updateValues(data)
                }
            })

        refInput.current.value = "";
        refInput.current.focus();
    }

    useEffect(() => {
        fetch("/api/current", {
            credentials: 'include',
            withCredentials: true,
            method: "POST",
            body: "",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then(response => {
                if (response.status !== 200) {
                    navigate('/org/login')
                } else {
                    return response.json()
                }
            })
            .then(data => {
                if(data!== undefined){
                    updateValues(data)

                    socket.on("event-"+data.org.id, function (data) {
                        updateValues(data)
                    });
                }
            })
    }, [])


    const updateValues = (data) => {
        console.log("data:")
        console.log(data)
        setCurrentValue(data.currentNumber)
        setCalledNumber(data.calledNumber)
        setOrgName(data.org.name)
        setDateTime(data.dateTime)
        setOrgModel(data)
    }

    return (
        <div className='content-centered'>
            <div className="card">
                <div className="container">
                    <h1 className='big-font'>{currentNumber}</h1>
                    <div className='small-font'><i className={"fa fa-clock-o"} /> {dateTime}</div>
                    <p> <i className={"fa fa-map-marker"} /> {orgName}</p>
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <input type="number" min="0" step="1" className='input-field' name="name" placeholder="Next Number" ref={refInput} />
                        <input type="submit" value="Call" />
                    </form>
                    <br/>
                    <br/>
                   {
                        orgModel? <Toggle orgModel={orgModel} key={dateTime}/>: <div/>
                   }
                    
                </div>
            </div>
            <div>
                <h3>Called: </h3>
                {calledNumber.map(x => <div key={x + '-' + Math.random()}>{x}</div>)}
            </div>
            <div>
            
            </div>
        </div>
    )
}
export default Updater;
