import React from 'react'
import {SocketContext, socket} from '../socket'
import Updater from '../components/Updater'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'


function OrgPage() {

return (
    <SocketContext.Provider value={socket}>
        <div className='base'>
            <Navbar selected={'control'}/>
            <Updater/>
        </div>
    </SocketContext.Provider>
)
}

export default OrgPage