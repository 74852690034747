import { Link,  useNavigate} from "react-router-dom";
import notfoundJpg from '../notfound.svg'

function NotFound() {

    const navigate = useNavigate();

    const handleClick = ()=>{
        navigate(-1);
    }

    return (
        <div >
            <div>
                <h2>Oops! You seem to be lost.</h2>
                <p>Let's start over again from <Link to='/'>Home</Link></p>
                <p>or <a href="#" onClick={handleClick} style={{cursor: 'pointer'}}>Back</a> to where you came from.</p>
            </div>
            <div>
                <img src={notfoundJpg} width={400} height={400}/>
            </div>
        </div>
    )
}

export default NotFound