import React from 'react'
import {SocketContext, socket} from '../socket'
import Counter from '../components/Counter'
import Footer from '../components/Footer'

function UserPage() {

return (
  <SocketContext.Provider value={socket}>
    <div className='base'>
      <Counter/>
      <div style={{ bottom:"5px", position: "fixed", left: "50%",transform: "translate(-50%, -50%)"}}>
                <Footer/>
            </div>
    </div>
    
  </SocketContext.Provider>
)
}

export default UserPage