import React,  {useEffect,useContext, useState, useRef}  from 'react'
import {Routes, Route, useNavigate} from 'react-router-dom';
import Footer from '../components/Footer';
var Hashes = require('jshashes')

function LoginPage() {
    const refUsername = useRef(null);
    const refPassword = useRef(null);

    const navigate = useNavigate();

    const [errorMsg, setErrorMsg] = useState('default')
    const [showError, setShowError] = useState(false)

    const handleSubmit = (event) => {
        event.preventDefault();
        let username = refUsername.current.value;
        let password = refPassword.current.value;

        if(username === '' || password === ''){
          setShowError(true)
          setErrorMsg("Please enter Username & Password")
          return;
        }

      const hashedPassword = new Hashes.SHA1().b64(password)
      fetch("/api/login", 
       {
            credentials: 'include',
            withCredentials: true,
            method: 'POST',
            body: JSON.stringify({
              "username": username,
              "password": hashedPassword
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'withCredentials': true
            },
       })
       .then(response=>{return response.json()})
       .then(data => {
          if(data.status === 'success'){
            console.log(`going to ${data.nextUrl}`)
            navigate(data.nextUrl);
          }
          else {
            setShowError(true)
            setErrorMsg(data.reason);
          }
       })
      
  }


  return (
    <div>
      <div className={'base'}>
        <div className='content-centered pad-top'>
                      <div className="card">
                          <div className="container">
                              <h1>Log in</h1> 
                              <form onSubmit={handleSubmit}>
                                  <input className='input-field' type="text" name="name" placeholder="Username" ref={refUsername} />
                                  <br></br>
                                  <input className='input-field' type="password" name="password" placeholder="password" ref={refPassword}/>
                                  {showError? <div style={{color: 'red'}}>{errorMsg}</div> : null}
                                  <br></br>
                                  <input type="submit" value="Login" />
                              </form>
                          </div>
                      </div>
              </div>
          
      </div>
    <Footer/>    
    </div>
  )
}

export default LoginPage