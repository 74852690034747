import React,  {useEffect,useContext, useState, useRef}  from 'react'
import Footer from '../components/Footer';
import SearchOrg from '../components/SearchOrg';
import Map from '../components/Map';
import AboutUs from './AboutUs';


function IndexPage() {

  const [location, setLocation] = useState([0,0])

  useEffect(() => {

  }, [])


  return (
    <div className='base'>
      <div className='content-centered pad-top'>
          <h3>Queue Check Online</h3>
          <p>Monitor queue number at your comfort!</p>
          <br/>
          <SearchOrg location={location}/>
          <br/>
          Alternatively, Scan the QR code at the host location.
          <br/>
          <br/>
          <br/>
          <Map onLocation={setLocation}/>
     
      </div>
      <br/>
      <Footer/> 
    </div>
  )
}

export default IndexPage