import React, { useState, useRef } from "react";
var Hashes = require('jshashes')

function CreateNewOrg() {

    const  [id, setId] =  useState('');
    const  [orgName, setOrgName] =  useState('');
    const  [category, setCategory] =  useState('');
    const  [address, setAddress] =  useState('');
    const  [latitude, setLatitude] =  useState('');
    const  [longitude, setLongitude] =  useState('');
    const  [msg, setMsg] =  useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        if(id === '' || orgName === '' || category === '' || address === '' || latitude === '' || longitude === ''){
            return;
        }

        const data = {
            id: id,
            name: orgName,
            category: category,
            address: address,
            lat: parseFloat(latitude),
            long: parseFloat(longitude)
        }
    
        fetch('/api/createOrg', {
            credentials: 'include',
            withCredentials: true,
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'withCredentials': true
            },
        })
        .then(response => {
            console.log(response.status)
            if(response.status !== 200){
                setMsg("some problem....")
                return null;
            }
            return response.json();
        })
        .then(data =>{
            if(data !== null){
                console.log(data)
                setId('')
                setCategory('')
                setOrgName('')
                setAddress('')
                setLatitude('')
                setLongitude('')
                setMsg(JSON.stringify(data))
            }  
        })
    }


    const handleIdChange = (event) => {
        event.preventDefault();
        setId(event.target.value);
    }


    const handleOrgNameChange = (event) => {
        event.preventDefault();
        setOrgName(event.target.value);
    }
    
    const handleCategoryChange = (event) => {
        event.preventDefault();
        setCategory(event.target.value);
    }
    const handleAddressChange = (event) => {
        event.preventDefault();
        setAddress(event.target.value);
    }

    const handleLatitudeChange = (event) => {
        event.preventDefault();
        setLatitude(event.target.value);
    }


    const handleLongitudeChange = (event) => {
        event.preventDefault();
        setLongitude(event.target.value);
    }


    return (
        <div className='content-centered pad-top'>
            <div className="card">
                <br />
                <h1><i className={"fa fa-university"} /></h1>
                <h4>Create new Organization</h4>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <input type="text" className='input-field' placeholder="id" value={id} onChange={handleIdChange}/>
                    <input type="text" className='input-field' placeholder="Org Name" value={orgName} onChange={handleOrgNameChange}/>
                    <input type="text" className='input-field' placeholder="Address" value={address} onChange={handleAddressChange}/>
                    <input type="number" className='input-field' placeholder="latitue" value={latitude} onChange={handleLatitudeChange}/>
                    <input type="number" className='input-field' placeholder="longitude" value={longitude} onChange={handleLongitudeChange}/>
                    <select name="category" id="category" value={category} onChange={handleCategoryChange}>
                        <option value="">Choose Category</option>
                        <option value="clinic">Clinic</option>
                        <option value="salon">Salon</option>
                    </select>
                    <input type="submit" value="Create new Org" />
                    {msg!=='' ? <div>{msg}</div> : null}
                </form>
            </div>
        </div>
    );
}

export default CreateNewOrg;