import React, { useEffect, useContext, useState, useRef, createContext } from 'react'
import { MapContainer, TileLayer, Marker , Popup} from 'react-leaflet'
import { Icon} from 'leaflet'
import { getDistance } from 'geolib';
import './css/map.css';

function Map ({onLocation}){

    const iconSize = 40;

    const clinicIcon = new Icon({
        iconUrl: "/marker/clinic_marker.png",
        iconSize: [iconSize, iconSize]
      });

      const salonIcon = new Icon({
        iconUrl: "/marker/salon.jpeg",
        iconSize: [iconSize*0.9, iconSize*0.9]
      });

      const hereIcon = new Icon({
        iconUrl: "/marker/here2.png",
        iconSize: [iconSize*1.2, iconSize*1.2],
      });


    const [longitude, setLongitude] = useState(103.8198);
    const [latitude, setLatitude] = useState(1.3521);
    const [mapReady,setMapReady] = useState(false)
    const [orgs, setOrgs] = useState([])

    const [clinicList, setClinicList] = useState([]);
    
    const iconMap = {
        'clinic': clinicIcon,
        'salon': salonIcon
    }


    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success);
            convertOrgsToMarkers();
          } else {
            console.log("Geolocation not supported");
          }


          fetch("/api/allorg")
          .then(response => {
              if (response.status !== 200) {
                  return undefined;
              }
              return response.json()
          })
          .then(data => {
              if (data !== undefined) {
                  console.log("size ="+data.org.length)
                  setOrgs(data.org);
                  convertOrgsToMarkers(data.org);
              }
          })

    }, [])

    function convertOrgsToMarkers(orgs){
        const marker = orgs?.map(org => {
            return {
                lat: org.lat,
                long: org.long, 
                orgId: org.id,
                address: org.address,
                category: org.category,
                popup: {name: org.name}
            }   
        });
        
        setClinicList(marker);
        console.log(marker?.length)
    }

    function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        setLongitude(longitude);
        setLatitude(latitude)
        setMapReady(true)
        console.log("ready....")
        console.log(getDistance({latitude: 1.3111946, longitude: 103.7684404}, {latitude: 1.3111946, longitude: 103.7684404}))
        onLocation([latitude, longitude])
      }

    return (
       
        <div className='content-centered'>
            {mapReady? 
            <div className='card' style={{paddingTop: "15px"}}>
            <MapContainer 
                key={JSON.stringify([latitude, longitude])}
                center={[latitude,longitude]} zoom={16} scrollWheelZoom={false}>
                <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                {clinicList?.map(x=>
                    <Marker position={[x.lat,x.long]} icon={iconMap[x.category]} key={x + '-' + Math.random()}>
                        <Popup>
                            <b>Name: {x.popup.name}</b><br/>
                            Address: {x.address}<br/>
                            Category: {x.category}<br/>
                            distance: {getDistance({"latitude": x.lat, "longitude": x.long}, 
                                                {"latitude": latitude, "longitude": longitude}) + "m away"}
                            <br/>
                            <br/>
                            <a href={`/queue/${x.orgId}`} >check Queue</a>
                        </Popup>
                    </Marker>
                )}

                <Marker position={[latitude,longitude]} icon={hereIcon}>
                    <Popup>
                        <br/>
                            You are here.
                        <br/>
                        <br/>
                    </Popup>
                </Marker>
            </MapContainer>
            </div>
        : 
        <div style={{"minHeight": "60vh", backgroundColor: "white",  alignItems: "center", textAlign: "center"}}>
                <p style={{paddingTop:"100px"}}>Please allow location permission to use map.</p>
        </div>   
        }
        </div>
    )
}
export default Map;