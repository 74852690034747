import React, { useEffect, useContext, useState } from 'react'
import { useToasts, } from 'react-toast-notifications';
import { useParams } from 'react-router';
import { SocketContext } from '../socket'
import PageVisibility from 'react-page-visibility';
import NotFound from './NotFound';

function Counter() {
    const { orgId } = useParams();
    const socket = useContext(SocketContext);
    const [currentNumber, setCurrentValue] = useState('-')
    const [clinic, setClinic] = useState({});
    const [calledNumber, setCalledNumber] = useState([12, 15]);
    const [dateTime, setDateTime] = useState('-');
    const [registrationClosed, setRegistrationClosed] = useState(false);
    const [org, setOrg] = useState({});


    const [resourceNotFound, setResourceNotFound] = useState(false)
    const [firstLoad, setFirstLoad] = useState(true)

    const { addToast } = useToasts();

    const handleVisibilityChange = (isVisible)=>{
        console.log("isVisible "+ isVisible +", socket connected = " + socket.connected);
        if(!socket.connected && !firstLoad){
            console.log("reconnecting after disconnected on return......")
            socket.reconnect();
        }
    }

    useEffect(() => {
        const eventId = 'event-' + orgId;
      
        socket.on(eventId, function (msg) {
            console.log(msg)
            addToast('Updated!', { appearance: 'success', autoDismiss: true, autoDismissTimeout: 2000 });
            setCurrentValue(msg.currentNumber)
            setCalledNumber(msg.calledNumber)
            setDateTime(msg.dateTime)
            setRegistrationClosed(msg.registrationClosed);
            setOrg(msg.org);
        });

        fetch("/api/current/" + orgId)
            .then(response => { 
                if(response.status !== 200){
                    setResourceNotFound(true);
                    return undefined;
                }
                return response.json() })
            .then(data => {
                if (data !== undefined) {
                    setResourceNotFound(false)
                    setCurrentValue(data.currentNumber)
                    setCalledNumber(data.calledNumber)
                    setDateTime(data.dateTime)
                    setRegistrationClosed(data.registrationClosed);
                    setOrg(data.org);
                }
                setFirstLoad(false)
            })

        fetch("/api/org/" + orgId).
            then(response => { return response.json() })
            .then(data => {
                setClinic(data.org)
            })

    }, [])

    return (
        <PageVisibility onChange={handleVisibilityChange}>
            <div className='content-centered pad-top'>
                {resourceNotFound? <NotFound/>: 
                    <div>
                        <div className="card">
                            <div className="container">
                                <h1 className='big-font'>{currentNumber}</h1>
                                <div className='small-font'><i className={"fa fa-clock-o"} /> {dateTime}</div>
                                <div><h4><i className={"fa fa-map-marker"} />  {clinic.name}</h4></div>
                                {org.category === 'clinic' ? 
                                    <div><h4><i className="fa fa-calendar-check-o" aria-hidden="true"/>&nbsp;Registion: 
                                    {registrationClosed? <p style={{ color: 'red' }}>Closed</p>: <p style={{ color: 'green' }}>Open</p> }
                                        </h4></div>
                                    : <div/>}  
                            </div>
                        </div>
                        <div>
                            <h3>Called: </h3>
                            {calledNumber.map(x => <div key={x.id + '-' + Math.random()}>{x}</div>)}
                        </div>
                    </div>
                }
            </div>
        </PageVisibility>
    )
}
export default Counter;
