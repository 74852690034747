import React from 'react'
import { Route, Routes } from "react-router-dom"
import UserPage from './page/UserPage'
import LoginPage from './page/LoginPage'
import ClinicPage from './page/OrgPage'
import SettingsPage from './page/SettingsPage'
import { ToastProvider} from 'react-toast-notifications';
import IndexPage from './page/IndexPage'
import NotFoundPage from './page/NotFoundPage'
import Admin from './page/AdminPage'
import AdminDashboard from './page/AdminDashboard'
import AboutUs from './page/AboutUs'
import ContactUs from './page/ContactUs'

function App() {

return (
  <ToastProvider>
  <Routes>
      <Route path="/queue/:orgId" element={<UserPage />} />
      <Route path="/org/login" element={<LoginPage />}  />
      <Route path="/org/control" element={<ClinicPage />} />
      <Route path="/org/settings" element={<SettingsPage />} />
      <Route path="/admin/control" element={<Admin />} />
      <Route path="/admin/dashboard" element={<AdminDashboard />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/" element={<IndexPage />} />
      <Route path='*' element={<NotFoundPage />}/>
    </Routes>
    </ToastProvider>
)
}

export default App