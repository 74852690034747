import React, { useState, useRef } from "react";
var Hashes = require('jshashes')

function PasswordForm() {

    const newPwRef = useRef(null);
    const newPwConfirmRef = useRef(null);

    const [showError, setShowError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [color, setColor] = useState('red')

    const handleSubmit = (event) => {
        event.preventDefault();
        if (newPwRef.current.value === newPwConfirmRef.current.value) {
            setShowError(false)

            const hashedPassword = new Hashes.SHA1().b64(newPwRef.current.value)
            fetch('/api/resetpw', {
                credentials: 'include',
                withCredentials: true,
                method: 'POST',
                body: JSON.stringify({
                    "password": hashedPassword
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'withCredentials': true
                },
            })
            .then(response => {
                console.log(response.status)
                if(response.status === 200){
                    setColor('green')
                }
                else{
                    setColor('red')
                }
                return response.json();
            })
            .then(data =>{
                if(data !== null){
                    setShowError(true)
                    setErrorMsg(data.result)
                }
                newPwRef.current.value = ""
                newPwConfirmRef.current.value = ""
            })
        }
        else {
            setShowError(true)
            setErrorMsg("Passwords does not match!")
        }
    }

    return (
        <div className='content-centered pad-top'>
            <div className="card">
                <br />
                <h4>Update Password</h4>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <input type="password" className='input-field' name="newpassword" placeholder="New Password" ref={newPwRef} />
                    <input type="password" className='input-field' name="newpasswordconfirm" placeholder="Repeat new Password" ref={newPwConfirmRef} />
                    {showError ? <div style={{ color: color }}>{errorMsg}</div> : null}
                    <input type="submit" value="Update" />
                </form>
            </div>
        </div>
    );

}

export default PasswordForm;