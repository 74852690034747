import React from 'react'
import config from '../config.json'


function Footer (){

    return (
        <div className='content-centered footer small-font' 
      >
             <a href='/'>Home</a> | <a href='/about'>About us</a>  <br/>
             Queue Check Online. version {config.appVersion}
        </div>
    )
}
export default Footer;
//  // 