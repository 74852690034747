import React, { useEffect, useContext, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import './css/toggle.css';

const Toggle = (param) => {
    
    const navigate = useNavigate();
    const orgId = param.orgModel.org.id;
    const initialState = param.orgModel.registrationClosed

    console.log("initial state: "+initialState)
    const [isRegistrationClosed, setToggle] = useState(initialState)

    const handleChange = (event)=>{
        
        fetch("/api/updateRegistration",
        {
            credentials: 'include',
            withCredentials: true,
            method: 'POST',
            body: JSON.stringify({
                "registrationClosed": !isRegistrationClosed,
                "orgId": orgId
            }),
            headers: {'Content-type': 'application/json; charset=UTF-8'},
        })
        .then(response => {
            if (response.status !== 200) {
                navigate('/org/login')
            }
            else {
                return response.json()
            }
        })
        .then(data => {
            if (data !== undefined) {
                console.log(data)
            }
        })

        setToggle(!isRegistrationClosed);
    }

    useEffect(() => {
        
    }, [])


    return (
        <div className='vertical-center'>
            <span>Registration:   &nbsp;  &nbsp; Open &nbsp;&nbsp;</span>
            <input type="checkbox" id="switch" defaultChecked={isRegistrationClosed} className="checkbox" onClick={handleChange} />
                <label htmlFor="switch" className="toggle">
                    <p>
                        &nbsp;
                    </p>
                </label>

            <span> &nbsp; Closed </span>
        </div>
    ) 
  
};

export default Toggle;