import React,  {useEffect,useContext, useState, useRef}  from 'react'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const QueueReset = (selected) => {

    const handleSubmit = (event) => {
        event.preventDefault();

        console.log("reset....")

        confirmAlert({
            title: 'Reset Queue Data?',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    fetch("/api/queuereset", {
                        credentials: 'include',
                        withCredentials: true,
                        method: "POST",
                        body: JSON.stringify({
                        }),
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                        },
                    })
                    .then(response => {
                        if (response.status !== 200) {
                            console.log("reset not success")
                        } else {
                            return response.json()
                        }
                    })
                    .then(data => {
                        if(data !== undefined){
                            console.log("reset successful!")
                        }
                    })
                }
              },
              {
                label: 'No',
                onClick: () => console.log("Cancel Reset")
              }
            ]
          });

    }


  return (
        <div className='content-centered'>
            <div className="card">
                <h3 style={{paddingTop: '20px'}}>Reset Queue Info</h3>
                <div className='small-font'>Clear called number and reset current number to 0 </div>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <input type="submit" value="Reset Queue Data" className='reset-btn' />
                </form>
            </div>
        </div>
  );
}

export default QueueReset;
