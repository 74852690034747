import React, { useEffect, useContext, useState, useRef } from 'react'
import AdminNavbar from '../components/AdminNavBar'
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import config from '../config.json'

function AboutUs() {

    const navigate = useNavigate();

    useEffect(() => {

    }, [])



    return (
        <div className='base'>
                <div className="container">
                    <div style={{paddingTop: "60px"}}>
                        <div className='content-centered pad-top'>
                            <img src="scan.png" height="200px" />
                            <br/>
                            <br/>
                        </div>
                           
                        Queue Check Online is a solution for hassle-free real-time queue status monitoring at your fingertips. 
                        Rather than waiting on premise, Our platform allows user to monitor the queue from anywhere by using the web and so that you can put the time to better use.
                        <br/>
                        <br/>

                        <strong>Why We Built Queue Check Online:</strong>
                        <br/>
                         
                         Like most parents, we have experienced the inconvenience of long waiting outside clinics, we recognized the need for a smarter way to monitor queues. 
                         Our journey started with the desire to make waiting times more productive and less stressful for everyone involved.
                        
                        
                        <br/>
                        <br/>
                        <strong>Contact us:</strong>
                        <br/>
                        If you are interested in exploring how Queue Check Online can help you, please reach out to us.
                        We're excited to hear from you! Whether you have questions, feedback, or suggestions, 
                        <br/>
                        <br/>
                        Contact us at <a href={"mailto:"+config.contactEmail} target="_new">{config.contactEmail}</a>

                        <br/>
                        <br/>
                        <br/>
                    
                    </div>
                </div>
            <br/>
            <Footer/>
        </div>
    )
}

export default AboutUs