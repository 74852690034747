import React, { useEffect, useState, useRef } from "react";
import {useNavigate } from 'react-router-dom';

function NameUpdate() {
    const navigate = useNavigate();
    const newOrgNameRef = useRef('');

    const [showError, setShowError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [org, setOrg] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        const newName = newOrgNameRef.current.value

        if(newName === ''){
            return;
        }
        console.log(org)
        fetch("/api/updateOrgName", {
            credentials: 'include',
            withCredentials: true,
            method: "POST",
            body: JSON.stringify({
                newOrgName: newName, 
                id: org.id
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
        .then(response => {
            if (response.status !== 200) {
                navigate('/org/login')
            } else {
                return response.json()
            }
        })
        .then(data => {
            if(data !== undefined){
                setOrg(data)
                newOrgNameRef.current.value = ""
                setShowError(true)
                setErrorMsg("Update Success!")
            }
        })
    }

    useEffect(() => {
        fetch("/api/current", {
            credentials: 'include',
            withCredentials: true,
            method: "POST",
            body: "",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
        .then(response => {
            if (response.status !== 200) {
                navigate('/org/login')
            } else {
                return response.json()
            }
        })
        .then(data => {
            if(data !== undefined){
                setOrg(data.org)
            }       
        })
    }, [])


    return (
        <div className='content-centered pad-top'>
            <div className="card" id="name-card">
                <br />
                <h4>Update Organization Name</h4>
                <h5>Current Name: {org.name}</h5>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <input type="text" className='input-field' name="newpassword" placeholder="New Org Name" ref={newOrgNameRef} />
                    {showError ? <div style={{ color: 'green' }}>{errorMsg}</div> : null}
                    <input type="submit" value="Update" />
                </form>
            </div>
        </div>
    );

}

export default NameUpdate;