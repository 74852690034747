
import React, { useEffect, useContext, useState, useRef } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import {Routes, Route, useNavigate} from 'react-router-dom';
import { getDistance } from 'geolib';
import config from '../config.json'


function SearchOrg(prop) {

    const navigate = useNavigate();

    const [orgs, setOrgs] = useState(); 
    const location = prop.location;

    useEffect(() => {
        fetch("/api/allorg")
            .then(response => {
                if (response.status !== 200) {
                    return undefined;
                }
                return response.json()
            })
            .then(data => {
                if (data !== undefined) {
                    setOrgs(data.org);
                }
            })
    }, [])


    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
    }

    const handleOnHover = (org) => {
        // the item hovered
        // console.log(result.id)
    }

    const handleOnSelect = (item) => {
        // the item selected
        // console.log(item)
        navigate('/queue/'+item.id);
    }

    const handleOnFocus = () => {
        console.log('Focused')
    }

    const formatResult = (org) => {
        return (
            <>
                {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {org.id}</span> */}
                <span style={{ display: 'block', textAlign: 'left' }}><b>{org.name} </b></span>
                <span style={{ display: 'block', textAlign: 'left'}}> category: {org.category},  {getOrgDistanceFromCenter(org)}m</span>
            </>
        )
    }

    function getOrgDistanceFromCenter(org){
        return getDistance(
            {latitude: location[0], longitude: location[1]}, 
            {latitude: org.lat, longitude: org.long})
    }

    return (
        <div className='base' style={{width: "90%", overflow: "visible", zIndex:10 }}>
            <ReactSearchAutocomplete
                items={filterBasedByDistance(orgs)}
                fuseOptions={{
                    keys: [
                        "name",
                        "category"
                    ],
                    threshold: 0.3,
                }}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                // autoFocus
                formatResult={formatResult}
                resultStringKeyName="name"
                placeholder="Search name or category"
            />
        </div>

    )



    function filterBasedByDistance(orgs){
        if(orgs=== undefined) return [];
        return orgs.filter(org => getOrgDistanceFromCenter(org) <= config.searchDistanceInMeters);
    }
}

export default SearchOrg