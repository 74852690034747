import React from 'react'
import Footer from '../components/Footer'
import UserPassReset from '../components/UserPasswordReset'
import AdminNavbar from '../components/AdminNavBar'
import CreateNewOrg from '../components/CreateNewOrg'
import CreateNewUser from '../components/CreateNewUser'
import AdminDashboard from './AdminDashboard'

function Admin() {

return (
    <div className='base'>
        
        <AdminNavbar selected={'control'}/>
        <AdminDashboard />
        <CreateNewOrg/>
        <CreateNewUser/>
        <UserPassReset/>  
    </div>
)
}

export default Admin