import React,  {useEffect,useContext, useState, useRef}  from 'react'
import {Routes, Route, useNavigate} from 'react-router-dom';

const Navbar = (selected) => {
    const refLogout = useRef(null);
    const navigate = useNavigate();

    const handleLogout = ()=>{
        fetch("/api/logout",{
            credentials: 'include',
            withCredentials: true,
        })
        .then(response => {
            return response.json()
        })
        .then(data =>{
            navigate("/org/login")
        })
    }

    const handleSettings = ()=>{
        fetch("/api/logout",{
            credentials: 'include',
            withCredentials: true,
        })
        .then(response => {
            return response.json()
        })
        .then(data =>{
            navigate("/org/login")
        })
    }

  return (
    <nav>
      <ul className="navbar">
        <li><a href="/org/control"  className={selected.selected==='control'? 'active': ''}>Control</a></li>
        <li><a href="/org/settings" className={selected.selected==='settings'? 'active': ''}>Settings</a></li>
        <li><a onClick={handleLogout}>Logout</a></li>
      </ul>
    </nav>
  );
}

export default Navbar;
