import { Link,  useNavigate} from "react-router-dom";
import Footer from '../components/Footer'
import notfoundJpg from '../notfound.svg'
import NotFound from "../components/NotFound";

 function NotFoundPage() {

    const navigate = useNavigate();

    const handleClick = ()=>{
        navigate(-1);
    }

    return (
        <div className="content-centered">
            <div className='base'>
                <NotFound/>
                <Footer/>
            </div>
        </div>
    )
}

export default NotFoundPage