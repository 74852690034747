import React, { useState, useRef } from "react";
var Hashes = require('jshashes')

function UserPassReset() {

    const handleSubmit = (event) => {
        event.preventDefault();
    }

    return (
        <div className='content-centered pad-top'>
            <div className="card">
                <br />
                <h4>User Password Reset</h4>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <input type="password" className='input-field' name="newpassword" placeholder="user Id" />
                    <input type="submit" value="Reset" />
                </form>
            </div>
        </div>
    );

}

export default UserPassReset;