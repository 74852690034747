import React, { useEffect, useContext, useState, useRef } from 'react'
import AdminNavbar from '../components/AdminNavBar'
import { useNavigate } from 'react-router-dom';

function AdminDashboard() {

    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [orgs, setOrgs] = useState([]);

    useEffect(() => {
        fetch("/api/all-users-orgs", {
            credentials: 'include',
            withCredentials: true,
            method: "POST",
            body: "",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then(response => {
                if (response.status !== 200) {
                    navigate('/org/login')
                } else {
                    return response.json()
                }
            })
            .then(data => {
                if(data!== undefined){
                    setOrgs(data.orgs)
                    setUsers(data.users)
                    
                }
            })
    }, [])



return (
    <div className='base'>
        {/* <AdminNavbar selected={'dashboard'}/>
        <br/>
        <br/> */}
        <div className="card">
            <div className="container">
                <h3>Users</h3>
                <table border={1}>
                    <thead>
                    <tr><th>User ID</th><th>Org ID</th><th>Active</th><th>Admin</th></tr>
                    </thead>
                    <tbody>
                {
                    users.map(x => 
                         (<tr key={Math.random()}><td>{x.userId}</td><td>{x.orgId}</td><td>{x.active?.toString()}</td><td>{x.admin?.toString()}</td></tr>)
                    )
            
                }
                </tbody>
                </table>
            </div>
        </div>
        <br/>
        <br/>

        <div className="card">
            <div className="container">
            <h3>Orgs</h3>
            <table border={1}>
            <thead>
            <tr><th>Org ID</th><th>Name</th><th>Category</th></tr>
            </thead>
            <tbody>
                {
                    orgs.map(x => 
                        (<tr key={Math.random()}><td>{x.id}</td><td>{x.name}</td><td>{x.category}</td></tr>)
                    )
                }
            </tbody>
            </table>
            </div>
        </div>
    </div>
)
}

export default AdminDashboard