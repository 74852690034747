import React, { useEffect, useContext, useState, useRef } from 'react'
import AdminNavbar from '../components/AdminNavBar'
import { useNavigate } from 'react-router-dom';
import config from '../config.json'
import Footer from '../components/Footer';

function ContactUs() {

    const navigate = useNavigate();

    useEffect(() => {

    }, [])

    return (
        <div className='base'>

                <div className="container">
                    <div style={{ paddingTop: "30px" }}>

                        If you would like to try out our system, please contact us at {config.contactEmail}

                    </div>
                </div>
            <br/>
            <div style={{ bottom:"5px", position: "fixed", left: "50%",transform: "translate(-50%, -50%)"}}>
                <Footer/>
            </div>
            
        </div>
    )
}

export default ContactUs