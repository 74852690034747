import React from 'react'
import {SocketContext, socket} from '../socket'
import Navbar from '../components/Navbar'
import PasswordForm from '../components/PasswordForm'
import QrCode from '../components/QrCode'
import NameUpdate from '../components/NameUpdate'
import QueueReset from '../components/QueueReset'


function SettingsPage() {

    return (
        <SocketContext.Provider value={socket}>
        <div className='base' id='some-page'>
            <Navbar selected={'settings'}/>
            <QueueReset/>
            <PasswordForm/>
            <NameUpdate/>
            <br/>
            <QrCode/>
        </div>
    </SocketContext.Provider>
    )
}

export default SettingsPage