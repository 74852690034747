import React, { useEffect, useContext, useState, useRef } from "react";
import QRCode from "react-qr-code";
import { useNavigate } from 'react-router-dom';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas'
import Constant from "../Config";

function QrCode() {
    const navigate = useNavigate();
    const [orgId, setOrgId] = useState('00000')
    const [showQrCode, setShowQrCode] = useState(false)
    const [host, setHost] = useState(window.location.host)
    const [org, setOrg] = useState(undefined)

    const handleDownload = (event) => {
        var source = window.document.getElementById("some-card");
        source.style.overflow = 'visible';
        html2canvas(source, {
            'windowWidth': 780,
            scale: 1.9
        }).then(canvas => {
        const type = "pdf";
          let dataURL = canvas.toDataURL('image/png');
          if (type === 'pdf') {
            const pdf = new jsPDF({
              orientation: "portrait",
              unit: "in",
              format: [16.6, 23.4]
            });
            
            pdf.addImage(dataURL, 'PNG', .6, 3.6);
            pdf.save(`poster.pdf`);
          }
        });
    }

    useEffect(() => {
        fetch("/api/current", {
            credentials: 'include',
            withCredentials: true,
            method: "POST",
            body: "",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then(response => {
                if (response.status !== 200) {
                    navigate('/org/login')
                }
                return response.json()
            })
            .then(data => {
                if (data !== undefined) {
                    console.log(data)
                    console.log(data.org.id)
                    setOrgId(data.org.id)
                    setOrg(data.org)
                    setShowQrCode(true)
                }
            })
    }, [])

    return (
        <div className='content-centered pad-top' id="some-div">
            <div className="card" id="some-card">
                    <div data-html2canvas-ignore={true} style={{paddingTop: "30px", paddingBottom: "30px"}}>
                        Below is a default poster with a unique QR code.<br/>
                        Download and print out the poster<br/> Paste the poster at visible location for easy QR scanning.
                    </div>
                <div style={{border: '1px solid red', margin: '0 40px 0 40px'}}>
                    <br />
                    <h2>Queue Check Online ® </h2>
                    <h4 style={{paddingTop: '30px'}}><i className={"fa fa-map-marker"} /> {org?.name} </h4>

                    <div>Monitor the queue from your mobile at anywhere!</div>
                    <br/>
                    <br/>
                    
                    <div style={{textAlign: 'left', display: "inline-block"}}>
                        1: Scan the QR code <br/>
                        2: Open the link<br/>
                        3: Monitor the queue from anywhere<br/>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    {showQrCode ?
                        <div id="qr-div" style={{paddingTop: '30px'}}>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "30%", width: "30%" }}
                                value={host + '/queue/' + orgId}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                        : null
                    }
                    
                    <div className="small-font" style={{paddingTop: '30px',paddingBottom: '20px'}}>Explore more at: https://queuecheck.online</div>    
                </div>
                
                <button data-html2canvas-ignore={true} onClick={handleDownload} id ="submit-button">Download Poster PDF</button>
            </div>
            <br />
            <br />
            
        </div>
    );

}

function saveSvg(svgEl, name) {
    svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    var svgData = svgEl.outerHTML;
    var preface = '<?xml version="1.0" standalone="no"?>\r\n';
    var svgBlob = new Blob([preface, svgData], { type: "image/svg+xml;charset=utf-8" });
    var svgUrl = URL.createObjectURL(svgBlob);
    var downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export default QrCode;